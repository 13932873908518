'use client';

import React, { AnchorHTMLAttributes } from 'react';
import { A, StyledNextLink } from '@app/core/components/link/style';

export type LinkProps = {
  link: {
    href: string;
    isExternal?: boolean;
    openInNewWindow?: boolean;
    isUsedForAuth?: boolean;
  };
} & Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'target'>;

export default function Link({
  link: { isExternal = false, openInNewWindow, href = '', isUsedForAuth },
  children,
  ...props
}: Readonly<LinkProps>) {
  const target = openInNewWindow ? '_blank' : '';
  const rel = isExternal ? 'noreferrer' : '';

  return openInNewWindow || isExternal || isUsedForAuth ? (
    <A href={href} target={target} rel={rel} {...props}>
      {children}
    </A>
  ) : (
    <StyledNextLink href={href} target={target} {...props}>
      {children}
    </StyledNextLink>
  );
}
