'use client';

import styled, { css } from 'styled-components';
import NextLink from 'next/link';

import { ParagraphTypes } from '@app/core/theme/basic/const';

export const A = styled.a(
  ({ theme: { COLOR, TYPOGRAPHY } }) => css`
    ${TYPOGRAPHY[ParagraphTypes.BODY_MD]}
    color: ${COLOR.BLUE.BLUE};
    padding: 0.5rem 0;
  `
);

export const StyledNextLink = styled(NextLink)(
  ({ theme: { COLOR, TYPOGRAPHY } }) => css`
    ${TYPOGRAPHY[ParagraphTypes.BODY_MD]}
    color: ${COLOR.BLUE.BLUE};
    padding: 0.5rem 0;
  `
);
