'use-client';

import styled, { css } from 'styled-components';
import { TBreakPoints } from '@app/core/components/layout/types';

export const Container = styled.div(
  ({ theme: { LAYOUT } }) => css`
    width: 100%;
    margin: 0 auto;
    max-width: ${LAYOUT.LAYOUT_MAX_CONTAINER_WIDTH}px;
    ${Object.entries(LAYOUT.LAYOUT_BREAKPOINTS).map(
      ([key, value]) => css`
        @media screen and (min-width: ${value}px) {
          padding-left: ${LAYOUT.LAYOUT_GRID_OFFSET[
            key as keyof TBreakPoints
          ]}px;
          padding-right: ${LAYOUT.LAYOUT_GRID_OFFSET[
            key as keyof TBreakPoints
          ]}px;
        }
      `
    )}
  `
);

export const Row = styled.div(
  ({ theme: { LAYOUT } }) => css`
    display: flex;
    flex-wrap: wrap;

    ${Object.entries(LAYOUT.LAYOUT_BREAKPOINTS).map(
      ([key, value]) => css`
        @media screen and (min-width: ${value}px) {
          margin-left: calc(
            -${LAYOUT.LAYOUT_GRID_GAP[key as keyof TBreakPoints]}px / 2
          );
          margin-right: calc(
            -${LAYOUT.LAYOUT_GRID_GAP[key as keyof TBreakPoints]}px / 2
          );
        }
      `
    )}
  `
);

interface StyledColumnProps {
  $columns: TBreakPoints;
  $offset: TBreakPoints;
}

export const StyledColumn = styled.div<StyledColumnProps>(
  ({ theme: { LAYOUT }, $columns, $offset }) => css`
    width: 100%;

    ${Object.entries(LAYOUT.LAYOUT_BREAKPOINTS).map(([key, value]) => {
      const columnBreakpoint = $columns?.[key as keyof TBreakPoints];
      const offsetBreakpoint = $offset?.[key as keyof TBreakPoints];

      return css`
        @media screen and (min-width: ${value}px) {
          padding-left: calc(
            ${LAYOUT.LAYOUT_GRID_GAP[key as keyof TBreakPoints]}px / 2
          );
          padding-right: calc(
            ${LAYOUT.LAYOUT_GRID_GAP[key as keyof TBreakPoints]}px / 2
          );
          width: ${columnBreakpoint !== undefined &&
          `calc(100% / ${LAYOUT.LAYOUT_GRID_COLUMNS} * ${columnBreakpoint})`};
          margin-left: ${offsetBreakpoint !== undefined &&
          `calc(100% / ${LAYOUT.LAYOUT_GRID_COLUMNS} * ${offsetBreakpoint})`};
        }
      `;
    })}
  `
);
