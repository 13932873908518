export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
  inline = 'inline',
  icon = 'icon',
}

export enum ButtonColor {
  transparent,
  blue,
  gold,
}

export enum IconPosition {
  left,
  right,
}
