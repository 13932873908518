import React from 'react';
import { Span } from './style';
import IconNames from './icon-names';

export interface IIconProps {
  name: IconNames;
  className?: string;
}

export default function Icon({ name, className }: Readonly<IIconProps>) {
  return (
    <Span className={className}>
      <svg className={`icon-${name}`}>
        <use href={`#icon-${name}`} />
      </svg>
    </Span>
  );
}
