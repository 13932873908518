'use client';

import styled from 'styled-components';
import Logo from './logo.svg';

export const StyledLogo = styled(Logo)`
  height: 6.4rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINTS.SM}px) {
    height: 7.04rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINTS.MD}px) {
    height: 12rem;
  }
`;
