'use client';

import styled from 'styled-components';
import { Column } from '@app/core/components/layout';

type StyledBackgroundProps = {
  $isFullHeight: boolean;
};

export const StyledHeader = styled.header`
  padding-top: 3.2rem;
  padding-bottom: 4.8rem;
  position: relative;
  width: 100%;
  overflow: visible;

  & + * {
    position: relative;
    margin-top: -4.8rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINTS.SM}px) {
    padding-top: 2.4rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINTS.MD}px) {
    padding-bottom: 5.2rem;

    & + * {
      margin-top: -5.2rem;
    }
  }
`;

export const StyledBackground = styled.div<StyledBackgroundProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(360deg, #000 0.98%, #003d7c 91.62%);
  transition: height 300ms ease-in-out;
  height: ${({ $isFullHeight }) => ($isFullHeight ? '100vh' : '100%')};
  z-index: ${({ theme: { Z_INDEX } }) => Z_INDEX.PAGE_BACKGROUND};
  pointer-events: none;
`;

export const StyledBackButtonContainer = styled.div`
  min-height: 4.8rem;
`;

export const StyledHeadingContainer = styled.div`
  position: relative;
  margin-bottom: 2.4rem;
`;

export const StyledLogoContainer = styled(Column)`
  text-align: right;
`;
