'use client';

import styled, { css } from 'styled-components';

import { ParagraphTypes } from '@app/core/theme/basic/const';

export interface IStyledParagraphProps {
  $type?: ParagraphTypes;
}

const StyledParagraph = styled.p<IStyledParagraphProps>(
  ({ $type = ParagraphTypes.BODY_MD, theme: { TYPOGRAPHY, COLOR } }) => css`
    ${$type && TYPOGRAPHY[$type]};
    color: ${COLOR.NEUTRALS.WHITE};
  `
);

export default StyledParagraph;
