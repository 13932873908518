'use client';

import IconNames from '@app/core/components/icon/icon-names';
import Link from '@app/core/components/link';
import { ButtonHTMLAttributes, ComponentProps, ReactNode } from 'react';
import {
  ArrowsContainer,
  LeftArrow,
  RightArrow,
  StyledButton,
  StyledButtonLabel,
  StyledIcon,
} from './style';
import {
  ButtonColor,
  ButtonType,
  IconPosition,
} from '@app/core/components/button/const';

interface IButtonProps {
  buttonType?: ButtonType;
  buttonColor?: ButtonColor;
  label?: string;
  children?: ReactNode;
  isForward?: boolean;
  isInverted?: boolean;
  icon?: {
    name: IconNames;
    position?: IconPosition;
  };
  className?: string;
}

interface IButtonLink extends IButtonProps {
  link: ComponentProps<typeof Link>['link'];
}

interface IButtonButton
  extends IButtonProps,
    ButtonHTMLAttributes<HTMLButtonElement> {}

type AllKeys<T> = T extends unknown ? keyof T : never;
type Id<T> = T extends infer U ? { [K in keyof U]: U[K] } : never;
type _ExclusifyUnion<T, K extends PropertyKey> = T extends unknown
  ? Id<T & Partial<Record<Exclude<K, keyof T>, never>>>
  : never;
type ExclusifyUnion<T> = _ExclusifyUnion<T, AllKeys<T>>;

type TButton = IButtonLink | IButtonButton;

type TProps = ExclusifyUnion<TButton>;

export default function Button({
  buttonType = ButtonType.primary,
  buttonColor = ButtonColor.blue,
  isForward = false,
  isInverted = false,
  label = '',
  icon,
  children,
  className,
  ...props
}: Readonly<TProps>) {
  const Component = 'link' in props ? Link : StyledButton;

  return (
    <StyledButton
      className={className}
      as={Component}
      $buttonType={buttonType}
      $buttonColor={buttonColor}
      aria-label={buttonType === ButtonType.icon ? label : ''}
      $isForward={isForward}
      {...props}
    >
      {icon && (icon.position === IconPosition.left || !icon.position) && (
        <StyledIcon name={icon.name} />
      )}
      {buttonType !== ButtonType.icon ? (
        <StyledButtonLabel $iconPosition={icon?.position}>
          {label || children}
        </StyledButtonLabel>
      ) : (
        ''
      )}
      {icon && icon.position === IconPosition.right && (
        <StyledIcon name={icon.name} />
      )}

      {isForward && buttonType === ButtonType.primary && (
        <ArrowsContainer>
          <LeftArrow />
          <RightArrow />
        </ArrowsContainer>
      )}
    </StyledButton>
  );
}
