'use client';

import StyledHeading, {
  IStyledHeadingProps,
} from '@app/core/components/typography/heading/style';
import { HeadingTypes } from '@app/core/theme/basic/const';
import { ElementType, LegacyRef, ReactNode } from 'react';

const defaultAs = {
  [HeadingTypes.HEADING_1]: 'h1',
  [HeadingTypes.HEADING_2]: 'h2',
  [HeadingTypes.HEADING_3]: 'h3',
  [HeadingTypes.HEADING_4]: 'h4',
  [HeadingTypes.HEADING_5]: 'h5',
  [HeadingTypes.HEADING_6]: 'h6',
};

interface IHeadingProps extends IStyledHeadingProps {
  type?: HeadingTypes;
  children?: ReactNode;
  as?: ElementType;
  ref?: LegacyRef<HTMLHeadingElement>;
}

function Heading({
  type = HeadingTypes.HEADING_1,
  ref,
  children,
  as,
  ...props
}: Readonly<IHeadingProps>) {
  return (
    <StyledHeading
      ref={ref}
      as={as || defaultAs[type]}
      {...{ $type: type, ...props }}
    >
      {children}
    </StyledHeading>
  );
}

export default Heading;
