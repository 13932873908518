'use client';

import styled, { css } from 'styled-components';
import Icon from '@app/core/components/icon';
import { keyframes } from '@app/core/components/button/keyframes';
import {
  ButtonColor,
  ButtonType,
  IconPosition,
} from '@app/core/components/button/const';

export const StyledIcon = styled(Icon)``;

export const StyledForwardIcon = styled(Icon)`
  z-index: -1;
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const StyledButton = styled.button<{
  $buttonType: ButtonType;
  $buttonColor: ButtonColor;
  $isForward: boolean;
}>(
  ({
    theme: { COLOR, TRANSITION, TYPOGRAPHY },
    $buttonType,
    $buttonColor,
    $isForward,
  }) => {
    const buttonBackgroundColorMap = {
      [ButtonColor.gold]: COLOR.GRADIENTS.GOLD,
      [ButtonColor.blue]: COLOR.GRADIENTS.BLUE,
      [ButtonColor.transparent]: 'transparent',
    };

    const buttonBorderColorMap = {
      [ButtonColor.gold]: COLOR.GRADIENTS.GOLD_SECONDARY,
      [ButtonColor.blue]: COLOR.GRADIENTS.BLUE_SECONDARY,
      [ButtonColor.transparent]: `linear-gradient(90deg,${COLOR.NEUTRALS.WHITE} 0%,${COLOR.NEUTRALS.WHITE} 100%)`,
    };

    return css`
      ${TYPOGRAPHY.BODY};
      ${keyframes};

      color: ${COLOR.NEUTRALS.WHITE};
      cursor: pointer;
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      transition: all 0.5s ${TRANSITION.TRANSITION_EASE_IN_OUT};
      z-index: 0;
      background: transparent;

      &:disabled {
        pointer-events: none;
        cursor: crosshair;
      }

      ${($buttonType === ButtonType.secondary ||
        $buttonType === ButtonType.primary) &&
      css`
        ${TYPOGRAPHY.BODY_SM_BOLD};
        min-width: 100%;
        padding: 1.6rem;
        height: 5.6rem;
        position: relative;
        overflow: hidden;
        border-width: 1px;
        border-image: ${buttonBorderColorMap[$buttonColor]} 10;
        border-style: solid;
        text-transform: uppercase;

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: ${buttonBackgroundColorMap[$buttonColor]};
          transition: opacity 0.5s ${TRANSITION.TRANSITION_EASE_IN_OUT};
          opacity: 1;
          z-index: -1;
        }

        &:hover {
          border-color: ${COLOR.NEUTRALS.GREY};
          border-image: linear-gradient(
              90deg,
              ${COLOR.NEUTRALS.GREY} 0%,
              ${COLOR.NEUTRALS.GREY} 100%
            )
            10;

          &:before {
            opacity: 0;
          }
        }
      `}

      ${$buttonType === ButtonType.primary &&
      css`
        padding-right: ${$isForward ? '4.2rem' : '1.6rem'};

        ${$isForward &&
        css`
          ${RightArrow} {
            opacity: ${$buttonColor === ButtonColor.transparent ? 0.1 : 0};
          }

          &:before {
            clip-path: polygon(
              0% 0%,
              calc(100% - 21px) 0%,
              calc(100% - 13px) 10px,
              calc(100% - 51px) 100%,
              0% 100%
            );
          }
        `}

        &:hover {
          ${LeftArrow} {
            background: ${COLOR.BLUE.BLUE};
            animation:
              btn-goto-arrow-left 0.1s linear 1,
              btn-goto-arrow-right 0.25s linear 1,
              btn-goto-idle 0.5s linear infinite;
          }

          ${RightArrow} {
            opacity: 0.1;
          }
        }
      `}

      ${$buttonType === ButtonType.secondary &&
      css`
        &:after {
          position: absolute;
          display: block;
          content: '';
          bottom: 0;
          right: 0;
          width: 0;
          height: 0;
          border-color: transparent transparent ${COLOR.BLUE.ACCENT};
          border-style: solid;
          border-width: 0 0 1rem 1rem;
          transition: all 0.15s ${TRANSITION.TRANSITION_EASE_IN_OUT};
        }

        &:hover {
          &:after {
            transition: all 0.3s ${TRANSITION.TRANSITION_EASE_IN_OUT};
            animation: btn-primary-outline-corner 1s linear infinite;
          }
        }
      `}

    ${$buttonType === ButtonType.inline &&
      css`
        ${TYPOGRAPHY.BODY_SM};

        border: none;
        height: 3.8rem;

        &:before {
          display: none;
        }
      `}

      ${$buttonType === ButtonType.icon &&
      css`
        display: inline-block;
        border-radius: 50%;
        width: 7.2rem;
        height: 7.2rem;
        border: none;

        ${StyledIcon} {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      `}
    `;
  }
);

export const StyledButtonLabel = styled.span<{ $iconPosition?: IconPosition }>`
  width: 100%;
  text-align: left;
  padding-left: ${({ $iconPosition }) =>
    $iconPosition === IconPosition.left ? '0.4rem' : '0'};
`;

export const ArrowsContainer = styled.span`
  position: absolute;
  display: block;
  z-index: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  line-height: 1em;
  vertical-align: middle;
`;

export const LeftArrow = styled.span`
  position: absolute;
  display: block;
  width: 46px;
  height: 100%;
  right: 13px;
  background: #fff;
  clip-path: polygon(
    calc(100% - 8px) 0%,
    100% 10px,
    8px 100%,
    0% 100%,
    calc(100% - 7.4px) 10px,
    calc(100% - 15.4px) 0%
  );
  animation: btn-goto-idle 2s linear infinite;
  z-index: 1;
  line-height: 1em;
  vertical-align: middle;
`;

export const RightArrow = styled.span`
  background: ${({ theme: { COLOR } }) => COLOR.NEUTRALS.GREY};
  right: 0;
  opacity: 0;
  clip-path: polygon(
    calc(100% - 21px) 0,
    100% 0%,
    100% 100%,
    0 100%,
    calc(100% - 13px) 10px
  );
  position: absolute;
  display: block;
  width: 51px;
  height: 100%;
  z-index: 1;
  line-height: 1em;
  vertical-align: middle;
  transition: opacity 0.15s
    ${({ theme: { TRANSITION } }) => TRANSITION.TRANSITION_EASE_IN_OUT};
`;
