import {
  TBreakPointEntries,
  BreakpointsConfig,
  TBreakPoints,
  LayoutTheme,
} from '@app/core/components/layout/types';

export const calculateMaximumContainerWidth = (
  maxContainerWidth: number,
  layoutGridOffset: number,
  nextBreakpointValue?: number
) =>
  Math.min(
    maxContainerWidth,
    (nextBreakpointValue ?? maxContainerWidth) - layoutGridOffset * 2
  );

export const getNextBreakpointWidth = (
  breakpointsEntries: TBreakPointEntries,
  idx: number
) => {
  const nextBreakpoint = breakpointsEntries[idx + 1];
  return nextBreakpoint && nextBreakpoint[1] - 1;
};

export const calculateMaximumColumnWidth = (
  maximumContainerWidth: number,
  gridGap: number,
  totalColumns: number,
  columns: number
) =>
  Math.floor(
    ((maximumContainerWidth + gridGap) / totalColumns) * columns - gridGap
  );

export const calculateColumnsPerBreakpoint = (
  layoutBreakPoints: BreakpointsConfig,
  layoutGridColumns: number,
  columns: TBreakPoints
) =>
  Object.keys(layoutBreakPoints).reduce(
    (accumulator, key, idx) => ({
      ...accumulator,
      [key]:
        // If columns are defined for this breakpoint, use that
        columns[key as keyof TBreakPoints] ??
        // If columns are not defined for this breakpoint, use the previous breakpoint's columns
        Object.values(accumulator)[idx - 1] ??
        // If columns are not defined for this breakpoint and there is no previous breakpoint, use the default number of columns
        layoutGridColumns,
    }),
    {}
  ) as TBreakPoints;

export function themeLayoutConsistencyHelper({
  LAYOUT_BREAKPOINTS,
  LAYOUT_MAX_CONTAINER_WIDTH,
  LAYOUT_GRID_COLUMNS,
  ...otherFields
}: LayoutTheme['LAYOUT']): [boolean, string | null] {
  const breakpointKeys = Object.keys(LAYOUT_BREAKPOINTS);

  let errorMessage: string | null = null;

  const isValid = Object.entries(otherFields).every(([fieldName, field]) => {
    const fieldKeys = Object.keys(field);

    const isEveryFieldKeyInBreakpoints = fieldKeys.every((key) =>
      breakpointKeys.includes(key)
    );
    if (!isEveryFieldKeyInBreakpoints) {
      errorMessage = `Some ${fieldName} keys are missing in LAYOUT_BREAKPOINTS`;
      return false;
    }

    const isEveryBreakpointsKeyInField = breakpointKeys.every((key) =>
      fieldKeys.includes(key)
    );
    if (!isEveryBreakpointsKeyInField) {
      errorMessage = `Some LAYOUT_BREAKPOINTS keys are missing in ${fieldName}`;
      return false;
    }

    return true;
  });

  return [isValid, errorMessage];
}
