'use client';

import styled, { css } from 'styled-components';

import { HeadingTypes } from '@app/core/theme/basic/const';

export interface IStyledHeadingProps {
  $type?: HeadingTypes;
  $hasCurrentColor?: boolean;
}

const StyledHeading = styled.h1<IStyledHeadingProps>(
  ({ $type, $hasCurrentColor, theme: { TYPOGRAPHY, COLOR } }) => css`
    ${$type && TYPOGRAPHY[$type]};
    color: ${$hasCurrentColor ? 'currentColor' : COLOR.NEUTRALS.WHITE};
  `
);

export default StyledHeading;
