/* eslint-disable react/jsx-props-no-spreading */
import React, { HTMLAttributes, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { TBreakPoints } from '@app/core/components/layout/types';
import { StyledColumn } from '@app/core/components/layout/style';
import ColumnContext from '@app/core/components/layout/context';
import useCalculateColumnContext from '@app/core/components/layout/hooks/use-calculate-column-context';
import { themeLayoutConsistencyHelper } from '@app/core/components/layout/helpers';

export { Container, Row } from '@app/core/components/layout/style';

export interface ColumnProps extends HTMLAttributes<HTMLDivElement> {
  columns: TBreakPoints;
  children: React.ReactNode;
  offset?: TBreakPoints;
  className?: string;
}

export function Column({
  columns = {
    XXS: 12,
    XS: 12,
    SM: 12,
    MD: 12,
    LG: 12,
  },
  offset = {},
  className,
  children,
  ...props
}: Readonly<ColumnProps>) {
  const { LAYOUT } = useTheme();
  const [isValid, errorMessage] = useMemo(
    () => themeLayoutConsistencyHelper(LAYOUT),
    [LAYOUT]
  );

  if (!isValid) {
    throw new Error(errorMessage!);
  }

  const columnContextValue = useCalculateColumnContext(columns);

  return (
    <ColumnContext.Provider value={columnContextValue}>
      <StyledColumn
        $columns={columns}
        $offset={offset}
        className={className}
        {...props}
      >
        {children}
      </StyledColumn>
    </ColumnContext.Provider>
  );
}
