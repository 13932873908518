'use client';

import { ReactElement } from 'react';
import {
  StyledBackButtonContainer,
  StyledBackground,
  StyledHeader,
  StyledHeadingContainer,
  StyledLogoContainer,
} from './style';
import { Column, Container, Row } from '@app/core/components/layout';
import IconNames from '@app/core/components/icon/icon-names';
import { Heading } from '@app/core/components/typography';
import Logo from '@app/core/components/logo';
import { ColumnProps } from '@app/core/components/layout/layout';
import { HeadingTypes } from '@app/core/theme/basic/const';

interface Props {
  hasFullHeightBackground?: boolean;
  children:
    | [ReactElement | string, ReactElement | string, ReactElement<ColumnProps>]
    | [ReactElement | string, ReactElement | string];
}

function Header({
  hasFullHeightBackground = false,
  children,
}: Readonly<Props>) {
  const [headerMenuSlot, headerTitleSlot, ...rest] = children;
  return (
    <StyledHeader>
      <StyledBackground $isFullHeight={hasFullHeightBackground} />
      <Container>
        <Row>
          <Column columns={{ XXS: 7 }}>
            <StyledBackButtonContainer>
              {headerMenuSlot}
            </StyledBackButtonContainer>
            <StyledHeadingContainer>
              <Heading type={HeadingTypes.HEADING_6} as="h1">
                {headerTitleSlot}
              </Heading>
            </StyledHeadingContainer>
          </Column>
          <StyledLogoContainer columns={{ XXS: 5 }}>
            <Logo name={IconNames.LOGO} />
          </StyledLogoContainer>
        </Row>
        {rest.length != 0 && <Row>{rest}</Row>}
      </Container>
    </StyledHeader>
  );
}

export default Header;
