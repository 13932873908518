import { css } from 'styled-components';

export const keyframes = css`
  @keyframes btn-icon-hover {
    0% {
      left: 0;
      font-size: 1.875rem;
      line-height: 2.5rem;
    }

    to {
      left: -0.3125rem;
      font-size: 2.5rem;
      line-height: 1.75rem;
    }
  }

  @keyframes btn-icon-hover-rounded {
    0% {
      left: 0;
      bottom: 0;
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1.875rem;
      margin-bottom: -0.6875rem;
    }

    to {
      left: -0.3125rem;
      bottom: -0.4375rem;
      width: 2rem;
      height: 2rem;
      font-size: 2.5rem;
      margin-bottom: -0.875rem;
    }
  }

  @keyframes btn-primary-top {
    0% {
      bottom: 1.875rem;
    }

    to {
      bottom: 0;
    }
  }

  @keyframes btn-primary-bottom {
    0% {
      right: 1.875rem;
      padding-top: 1.875rem;
    }

    to {
      right: 0;
      padding-top: 0;
    }
  }

  @keyframes btn-primary-corner {
    0% {
      bottom: 0;
    }

    to {
      bottom: -1.875rem;
    }
  }

  @keyframes btn-primary-outline-corner {
    0% {
      border-left-width: 1.875rem;
      border-bottom-width: 1.875rem;
    }

    to {
      border-left-width: 0;
      border-bottom-width: 0;
    }
  }

  @keyframes btn-goto-idle {
    0% {
      opacity: 1;
      transform: translateX(-21px);
    }

    to {
      opacity: 0;
      transform: translateX(0);
    }
  }

  @keyframes btn-goto-arrow-left {
    0% {
      transform: translateX(0);
    }

    to {
      transform: translateX(7.5rem);
    }
  }

  @keyframes btn-goto-arrow-right {
    0% {
      transform: translateX(-31.25rem);
    }

    to {
      transform: translateX(0);
    }
  }
`;
