enum IconNames {
  TRUE = 'true',
  FALSE = 'false',
  LOGO = 'logo',
  SORT_AMOUNT_ASC = 'sort-amount-asc',
  SETTINGS = 'android-settings',
  CHEVRON_LEFT_DOUBLE = 'chevron-left-double',
  CHEVRON_RIGHT_DOUBLE = 'chevron-right-double',
  CHEVRON_LEFT = 'chevron-left-',
  CHEVRON_RIGHT = 'chevron-right',
  CHECKMARK_CIRCLED = 'checkmark-circled',
  REMOVE_CIRCLE = 'android-remove-circle',
  CANCEL = 'android-cancel',
  STATS_BARS = 'stats-bars',
  CLOSE = 'close',
}

export default IconNames;
