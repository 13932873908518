import { useContext, useMemo } from 'react';
import { useTheme } from 'styled-components';
import {
  TBreakPointEntries,
  TBreakPoints,
} from '@app/core/components/layout/types';
import ColumnContext, {
  IColumnContext,
} from '@app/core/components/layout/context';
import {
  calculateColumnsPerBreakpoint,
  calculateMaximumColumnWidth,
  calculateMaximumContainerWidth,
  getNextBreakpointWidth,
} from '@app/core/components/layout/helpers';

const useCalculateColumnContext = (columns: TBreakPoints) => {
  const columnContext = useContext(ColumnContext);

  const {
    LAYOUT: {
      LAYOUT_BREAKPOINTS,
      LAYOUT_MAX_CONTAINER_WIDTH,
      LAYOUT_GRID_OFFSET,
      LAYOUT_GRID_GAP,
      LAYOUT_GRID_COLUMNS,
    },
  } = useTheme();

  // Create object with amount of columns per breakpoint
  const columnsPerBreakpoint: TBreakPoints = useMemo(
    () =>
      calculateColumnsPerBreakpoint(
        LAYOUT_BREAKPOINTS,
        LAYOUT_GRID_COLUMNS,
        columns
      ),
    [columns, LAYOUT_BREAKPOINTS, LAYOUT_GRID_COLUMNS]
  );

  const breakpointsEntries: TBreakPointEntries = Object.entries(
    LAYOUT_BREAKPOINTS
  ) as TBreakPointEntries;

  const columnContextValue: IColumnContext = useMemo(
    () =>
      breakpointsEntries.reduce((accumulator, [key, value], idx) => {
        const nextBreakpointWidth = getNextBreakpointWidth(
          breakpointsEntries,
          idx
        );

        // If a column is wrapped in another column, That parent column's max width becomes the current columns max container width
        const { maximumColumnWidth: maximumColumnWidthFromContext } =
          columnContext?.[key] || {};

        const maximumContainerWidth =
          maximumColumnWidthFromContext ||
          calculateMaximumContainerWidth(
            LAYOUT_MAX_CONTAINER_WIDTH,
            LAYOUT_GRID_OFFSET[key as keyof TBreakPoints],
            nextBreakpointWidth
          );

        // Calculate the maximum width of a column based on the maximum container width, the amount of columns and the column gaps
        const maximumColumnWidth = calculateMaximumColumnWidth(
          maximumContainerWidth,
          LAYOUT_GRID_GAP[key],
          LAYOUT_GRID_COLUMNS,
          columnsPerBreakpoint[key] ?? 0
        );

        return {
          ...accumulator,
          [key]: {
            breakpointValue: value,
            nextBreakpointWidth,
            maximumColumnWidth,
          },
        };
      }, {}),
    [
      breakpointsEntries,
      columnContext,
      columnsPerBreakpoint,
      LAYOUT_GRID_COLUMNS,
      LAYOUT_GRID_GAP,
      LAYOUT_GRID_OFFSET,
      LAYOUT_MAX_CONTAINER_WIDTH,
    ]
  );

  return columnContextValue;
};

export default useCalculateColumnContext;
