'use client';

import StyledParagraph, {
  IStyledParagraphProps,
} from '@app/core/components/typography/paragraph/style';
import { ParagraphTypes } from '@app/core/theme/basic/const';
import { ReactNode } from 'react';

interface IParagraphProps extends IStyledParagraphProps {
  type?: ParagraphTypes;
  children?: ReactNode;
}

function Paragraph({ type, children, ...props }: Readonly<IParagraphProps>) {
  return (
    <StyledParagraph {...{ $type: type, ...props }}>{children}</StyledParagraph>
  );
}

export default Paragraph;
